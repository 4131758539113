.invoice-card {
  background: #ffffff;
  border: 0.5px solid #bdbdbd;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: relative;
  margin-bottom: 10px;
  padding: 10px;
  font-family: "BPG Nino Medium", sans-serif;
  font-size: 15px;
  display: grid;
  grid-template-columns: 20% 20% 40% 20%;
  cursor: pointer;
  font-size: 12px;
}

.pdf-download {
  cursor: pointer;
}

.cancel-invoice {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
}

.cancel-form,
.close-form {
  width: 500px;
}

.pdf-and-close {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 25px;
}

.close-form {
  margin-top: -20px;
}

.invoice-cards-header {
  background: #ffffff;
  border: 0.5px solid #bdbdbd;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: relative;
  font-size: 13px;
  margin-bottom: 10px;
  padding: 10px;
  font-family: "BPG Nino Medium", sans-serif;
  font-size: 15px;
  display: grid;
  grid-template-columns: 20% 20% 40% 20%;
  cursor: pointer;
}

.request-invoice-card-header {
  background: #ffffff;
  border: 0.5px solid #bdbdbd;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: relative;
  font-size: 13px;
  margin-bottom: 10px;
  padding: 10px;
  font-family: "BPG Nino Medium", sans-serif;
  font-size: 15px;
  display: grid;
  grid-template-columns: 50% 50%;
  cursor: pointer;
}

.request-invoice-card {
  background: #ffffff;
  border: 0.5px solid #bdbdbd;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: relative;
  margin-bottom: 10px;
  padding: 10px;
  font-family: "BPG Nino Medium", sans-serif;
  font-size: 15px;
  display: grid;
  grid-template-columns: 50% 50%;
  cursor: pointer;
  font-size: 12px;
  min-height: 160px;
}

.invoice-column {
  border-right: 1px solid black;
  padding: 5px 15px;
}

.invoice-card-text {
  display: flex;
  align-items: center;
  margin-bottom: 2px;
  border-bottom: 1px solid black;
  padding: 8px 10px;
  gap: 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.close-transaction-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.payments-controlbar {
  display: flex;
  align-items: center;
  gap: 20px;
  font-size: 28px;
  font-weight: 500;
  color: var(--errorColor);
}

.payments-controlbar > span {
  font-size: 12px;
}

.transaction-comment-input {
  margin-bottom: -5px;
  outline: none;
  border: none;
  font-size: 11px;
  border-bottom: 1px solid black;
  width: 100%;
  padding-bottom: 8px;
  padding-left: 16px;
  margin-left: 2px;
}

.transaction-comment-input:focus {
  outline: none;
}

.close-button {
  background-color: var(--errorColor);
  color: white;
  padding: 5px;
  text-align: center;
  border-radius: 5px;
  display: inline;
  align-self: flex-end;
  cursor: pointer;
}

.comment-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.invoice-list {
  overflow-y: auto;
}

.invoice-table-container {
  display: flex;
  flex-direction: column;
}

.approve-checkbox {
  align-self: flex-end;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;
}

.close-button {
  padding-top: 7px;
}

.show-more {
  margin-top: 20px;
}

.show-more:hover {
  text-decoration: underline;
  cursor: pointer;
}

.card-selected {
  border: 5px solid var(--errorColor);
}

.invoice-edit-icon {
  cursor: pointer;
}

.card-input {
  max-width: 80px;
}

.save-button {
  background-color: var(--errorColor);
  padding: 5px 8px;
  color: white;
  text-align: center;
  display: inline;
  width: fit-content;
  cursor: pointer;
  border-radius: 4px;
  font-size: 14px;
}

.invoice-edit-buttons {
  position: absolute;
  right: 10px;
  top: 5px;
}
