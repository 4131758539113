.apartment-filter {
  padding: 0 50px;
  padding-bottom: 10px;
  width: 100%;
}

.apartment-filter > label,
.message-form > label {
  font-family: "BPG Nino Medium", sans-serif;
  font-weight: 700;
  font-size: 14px;
}

.apartment-filter-inputs {
  margin-top: 30px;
  width: 100%;
}

.apartment-list {
  margin-top: 0px;
  max-height: 250px;
  overflow-y: auto;
}

.apartments-col,
.categories-col {
  padding: 0;
}

.edit-icons > img {
  cursor: pointer;
}

.categories-col {
  padding: 0 30px;
}

.categories-col > label {
  margin-bottom: 100px;
  font-size: 15px;
  font-weight: 900;
}

.checkbox-row {
  border-bottom: 1px solid var(--borderColor);
  padding: 10px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 6px;
  cursor: pointer;
}

.subcategory-list {
  padding-left: 50px;
}

.checkbox-row-selected {
  background-color: #f0f0f7;
}

.apartment-filter-row {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.apartment-filter-clear-button,
.apartment-put-button {
  width: 100%;
  display: inline;
  text-align: center;
  background-color: #bdbdbd;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 8px 0;
  box-shadow: 2px 5px 6px rgb(170, 170, 170);
  font-size: 12px;
  color: white;
}

.apartment-put-button {
  background-color: var(--errorColor);
}

.apartment-filter-clear-button,
.apartment-put-button {
  width: 46%;
  display: inline;
  text-align: center;
  background-color: #bdbdbd;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 8px 0;
  box-shadow: 2px 5px 6px rgb(170, 170, 170);
  font-size: 11px;
  cursor: pointer;
  color: white;
}

.save-period-button {
  background-color: #10202b;
  color: white;
  font-size: 12px;
  width: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 30px;
  border-radius: 5px;
  cursor: pointer;
}

.apartment-put-button {
  background-color: var(--errorColor);
}

.category-list {
  margin-top: 20px;
}

.fee-category {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #bdbdbd;
  font-size: 15px;
  height: 50px;
  margin-top: 5px;
  font-weight: 900;
  gap: 20px;
}

.tax-category-container {
  display: flex;
  flex-direction: column;
}

.save-period {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.save-period-dates {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.warning-text {
  color: #333333;
  font-size: 13px;
  margin-top: 40px;
}

@media only screen and (max-width: 1300px) {
  .apartment-filter-row {
    flex-direction: column;
  }
  .apartment-filter-clear-button,
  .apartment-put-button {
    width: 100%;
  }
}
