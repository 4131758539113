.verification-input-character {
  border: none;
  border-bottom: 1px solid #828282;
}

.vi:focus {
  outline: none;
  border: none;
}

.verification-input-character-selected {
  border: none;
  outline: none;
  border: 0px solid red;
  border-bottom: 1px solid #828282;
}

.verification-input-character-inactive {
  background-color: white;
}

.sms-verification {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  text-align: center;
}

.sms-verification > label {
  font-family: "BPG Nino Mtavruli";
  margin-top: 20px;
}

.sms-verification > p {
  font-family: "BPG Nino Medium";
  width: 400px;
  margin: 10px;
  font-size: 12px;
  color: #10202b;
}
