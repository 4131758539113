.navbar-container {
    background: var(--lightCyan);
    padding: 15px 100px;
    padding-right: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.navbar-right {
    gap: 20px;
}

.navbar-right > .text-link {
    font-weight: 700;
    font-size: 15px;
    max-width: 200px;
    cursor: pointer;
    text-align: center;
    font-size: 14px;
}

.navbar-right > .text-link:hover{
    color: var(--errorColor)
}

.nav-selected {
    color: var(--errorColor);
}

.home-icon {
    margin-top: 3px;
    width: 15px;
}

.instruction-button {
    text-decoration: none;
    color: black;
    padding: 10px 17px;
    border-radius: 20px;
    border: 1px solid gray;
    text-align: center;
    display: flex;
    justify-content: center;
    font-size: 12x;
    align-items: center;
}

@media only screen and (max-width: 1200px) {
    .navbar-right > .text-link {
        font-size: 12px;
    }

    .navbar-right {
        gap: 10px;
        flex-direction: column;
    }
}