@font-face {
  font-family: "BPG Nino Mtavruli";
  src: url("./assets/bpg_nino_mtavruli_normal.ttf");
}

@font-face {
  font-family: "BPG Nino Medium";
  src: url("./assets/bpg_nino_medium.ttf");
}

body {
  margin: 0;
  font-family: "BPG Nino Mtavruli";
}

:root {
  --darkPrimary: #333333;
  --darkSecondary: #4f4f4f;
  --backgroundLight: #f0f0f7;
  --backgroundGray: #e5e6ee;
  --errorColor: #daa161;
  --lightCyan: #f0f0f7;
  --borderColor: #828282;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.icon {
  cursor: pointer;
}

.checkbox-disabled {
  opacity: 0.8;
}

.d-flex-centered {
  display: flex;
  align-items: center;
  gap: 10px;
}

button {
  all: unset;
  cursor: pointer;
}

.column-flex-centered {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.row-flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.shadow {
  box-shadow: 0px 8px 22px rgb(170, 170, 170);
}

input:focus {
  outline: 1px solid var(--darkPrimary);
}

.page-container {
  background-color: var(--backgroundGray);
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
}

.logo {
  font-family: "Antonio", sans-serif;
  font-weight: 500;
  font-size: 70px;
  user-select: none;
}

.text-link {
  color: inherit;
  text-decoration: inherit;
  width: clamp(300px, 30vw, 100%);
}

.link {
  color: inherit;
  text-decoration: inherit;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  width: 20px;
  height: 20px;
  padding: 0px;
  margin: 0px;
}

.modal-overlay {
  background-color: rgba(0, 0, 0, 0.4);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 2;
}

.modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 10px;
  padding: 10px;
  z-index: 2;
}

.confirm-modal {
  padding: 70px 30px 30px 30px;
  font-family: "BPG Nino Medium";
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 40px;
}

.confirm-modal > p {
  max-width: 300px;
}

.confirm-button {
  background-color: black;
  color: white;
  display: inline;
  padding: 8px 20px;
  cursor: pointer;
  font-size: 14px;
  border-radius: 5px;
}

.loader-black {
  border: 3px solid white;
  border-radius: 50%;
  border-top: 3px solid black;
  width: 20px;
  height: 20px;
  -webkit-animation: spin 1s linear infinite; /* Safari */
  animation: spin 1s linear infinite;
}

.error-msg {
  position: absolute;
  height: fit-content;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1001;
  text-align: center;
  width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* width */
::-webkit-scrollbar {
  width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
