.message-box {
    font-family: 'BPG Nino Medium';
    font-size: 16px;
    background-color: white;
    padding: 30px;
    padding-bottom: 40px;
    width: 80%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    color: black;
    font-weight: 900;
    border-radius: 5px;
    box-shadow: 0px 0px 4px rgb(204, 204, 204);
}