.filter-container {
  background-color: var(--lightCyan);
  padding: 5px 50px 15px 50px;
  margin-bottom: 10px;
  margin-top: -10px;
  border: 1px solid #bdbdbd;
  font-family: "BPG Nino Medium";
}

.filter-row {
  display: flex;
  align-items: center;
  gap: 50px;
  margin-top: 10px;
}

.filter-dropdown-container {
  position: relative;
  display: inline;
  width: 100%;
}

.filter-dropdown-selector {
  display: block;
  position: relative;
  align-items: center;
  font-size: 12px;
  background-color: white;
  cursor: pointer;
  padding: 10px 20px 10px 20px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  height: 35px;
  overflow-y: hidden;
  overflow-x: hidden;
  font-family: "BPG Nino Medium", sans-serif;
}

.filter-arrow {
  position: absolute;
  right: 20px;
  top: 12px;
}

.filter-x {
  position: absolute;
  top: 8px;
  right: 8px;
}

.filter-dropdown-list {
  font-size: 10px;
  width: 100%;
  max-height: 300px;
  overflow-y: auto;
  position: absolute;
  background-color: white;
  border: 1px solid #bdbdbd;
  border-top: none;
  top: 35px;
  z-index: 1000;
}

.filter-dropdown-element {
  padding: 10px 10px;
}

.filter-dropdown-element:hover {
  background-color: #e0e0e0;
}

.filter-input {
  position: relative;
  display: block;
  align-items: center;
  font-size: 12px;
  background-color: white;
  cursor: pointer;
  width: 100%;
  padding: 10px 20px 10px 20px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  font-family: "BPG Nino Medium";
}

.filter-date-input {
  position: relative;
  display: block;
  align-items: center;
  font-size: 12px;
  background-color: white;
  cursor: pointer;
  width: 100%;
  padding: 10px 20px 10px 20px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  font-family: "BPG Nino Medium";
}

.filter-input-container {
  position: relative;
  width: 100%;
}

.phone-input-container {
  display: flex;
  align-items: center;
  width: 100%;
}

.date-input-container {
  width: 100%;
  position: relative;
}

.date-placeholder {
  position: absolute;
  background-color: white;
  top: 2px;
  left: 20px;
  font-size: 13px;
  color: var(--borderColor);
  height: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.phone-index-dropdown {
  width: 250px;
  font-size: 12px;
}

.phone-input {
  position: relative;
  display: block;
  align-items: center;
  font-size: 12px;
  background-color: white;
  cursor: pointer;
  width: 100%;
  padding: 10px 20px 10px 20px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  font-family: "BPG Nino Medium";
}

.filter-buttons {
  display: flex;
  align-items: center;
  gap: 20px;
  width: 100%;
  justify-content: flex-end;
}

.filter-buttons > * {
  width: 140px;
  text-align: center;
}

.filter-clear-button {
  background-color: #bdbdbd;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  margin-top: 10px;
  padding: 10px 40px;
  border-radius: 5px;
  color: white;
  box-shadow: 2px 5px 6px rgb(170, 170, 170);
  cursor: pointer;
}

@media only screen and (max-width: 1350px) {
  .filter-row {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
    gap: 10px;
  }
  .input-placeholder {
    display: none;
  }
}

@media only screen and (max-width: 750px) {
  .filter-row {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto;
    gap: 10px;
  }
}

@media only screen and (max-width: 1100px) {
  .date-placeholder {
    top: -8px;
    left: 5px;
    font-size: 11px;
    padding: 2px;
    width: fit-content;
    height: auto;
  }
}
