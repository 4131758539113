.videos{
    display: flex;
    width: 100%;
    height: auto !important;
    justify-content: space-between;
    flex-flow: row wrap;
    
    
}
.videos::after{
    content: '';
    width: 32%;
  }

.tutorials-container{
padding: 20px 40px 100px 40px;
background-color: var(--backgroundGray);
}

/* ----------------- Responsive part  ------------- */
@media screen and (max-width: 900px){

  .videos{
     justify-content: center;
     align-items: center;
  }
  .videos::after{
    content: '';
    width: 0;
  }
}
@media screen and (max-width: 900px){

  .videos{
     justify-content: center;
     align-items: center;
  }
  .videos::after{
    content: '';
    width: 0;
  }
}