.services-container {
    width: 100%;
    flex-grow: 1;
}

.table-container {
    width: 100%;
    padding: 15px 30px;
    overflow-y: auto;
}
