.header-container {
    background-color: white;
    width: 100%;
    display: flex;
    padding: 10px 40px;
    justify-content: space-between;
}

.header-left, .header-right {
    font-size: smaller;
    display: flex;
    align-items: center;
    gap: 20px;
}

.header-left {
    gap: 10px;
}

.header-left > h1 {
    font-size: 35px;
    margin-top: -10px;
    cursor: pointer;
}

.header-left p {
    margin-top: 3px;    
    font-size: 11px;
}


.header-right p {
    font-family: 'BPG Nino Medium', sans-serif;
    font-size: 16px;
    text-transform: uppercase;
}

.logout-icon {
    width: 20px;
    cursor: pointer;
}

.logo {
    font-size: 35px;
    margin-top: -5px;
}