.ratings_card{
    height: auto;
    width: 32%;
    background-color: white;
    margin-bottom: 20px;
    padding: 20px 0px 55px 0px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    align-items: center;
    border: 0.5px solid #BDBDBD;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.sumCard{
    background-color: #DAA161;
    color:white
}
.rating-card-head{
    width: 100%;
    padding: 15px 16px 16px 16px;
    text-align: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 20px;
    box-sizing: border-box;
    
    
}
.clickable:hover{
background-color: #f4f3f3;
cursor: pointer;
}
._card_name{
    margin: 0 auto;
}
.right-arrow{
    cursor: pointer;
}
.rating-line{
display: flex;
box-sizing: border-box;
margin-top: 7px;
}
.rating-line img{
 margin-right: 7px;
}
.amount{
    margin-left: 12%;
}
.lower-ratings-container{
    display: flex;
    flex-direction: column;
    width: 60%;
}
.lil-header{
   display: flex;
   justify-content: space-between;
}
@media screen and (max-width: 1300px){

    .ratings_card{
        width: 47%;
    }
    .amount{
        margin-left: 26%;
    }
}
@media screen and (max-width: 950px){

    .ratings_card{
        width: 60%;
    }
    .amount{
        margin-left: 18%;
    }
}