.notifications-card,
.notifications-card-received {
  background: #ffffff;
  border: 0.5px solid #bdbdbd;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: relative;
  margin-bottom: 10px;
  padding: 10px;
  font-family: "BPG Nino Medium", sans-serif;
  font-size: 15px;
  display: grid;
  grid-template-columns: 25% 40% 20% 15%;
  cursor: pointer;
  font-size: 12px;
  min-height: 160px;
}

.notifications-card-received {
  grid-template-columns: 20% 50% 30%;
}

.notification-column {
  border-right: 1px solid black;
  padding: 5px 15px;
  position: relative;
  max-height: 300px;
  overflow-y: auto;
  word-wrap: break-word;
}

.addresate-row-container {
  position: relative;
}

.comment-text,
.my-comment-text {
  align-self: flex-end;
  max-width: 450px;
  word-break: break-all;
}

.comment-text {
  width: 100%;
}

.more-addresate {
  font-weight: 900;
  margin-top: 20px;
  align-self: flex-end;
}

.addresates-column {
  display: flex;
  flex-direction: column;
}

.more-addresate:hover {
  text-decoration: underline;
}

.addresate-row {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
}

.addresate-data {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.comment-input {
  padding: 8px 10px;
  font-size: 12px;
  border: 1px solid #828282;
  width: 100%;
  padding-right: 80px;
}

.comment-input:focus {
  outline: none;
}

.comment-section {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  padding: 0px 0;
  padding-bottom: 20px;
}

.comment-input-container {
  position: relative;
  width: 90%;
}

.send-icon {
  position: absolute;
  right: 5px;
  top: 5px;
  cursor: pointer;
}

.card-files {
  color: blue;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}

.card-file:hover {
  text-decoration: underline;
}

.card-file > a {
  text-decoration: none;
  color: inherit;
}

.notification-card-actions {
  position: absolute;
  bottom: 5px;
  right: 5px;
  display: flex;
  align-items: center;
  gap: 5px;
}

.comments-container {
  width: 100%;
  margin: 20px;
  margin-right: 70px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-height: 150px;
  overflow: auto;
  padding-right: 10px;
  padding-bottom: 20px;
  padding-top: 10px;
}

.comment-container,
.my-comment-container {
  font-family: "BPG Nino Medium";
  font-size: 14px;
  color: #333333;
  display: flex;
  gap: 10px;
}

.my-comment-container {
  flex-direction: row-reverse;
}

.comment-date {
  font-size: 8px;
}

.comment-chat-icon {
  width: 15px;
  height: 13px;
}

.my-comment,
.comment {
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.chat-pointer {
  position: relative;
}

.chat-pointer-number {
  position: absolute;
  color: white;
  left: 50%;
  top: 50%;
  font-size: 9px;
  transform: translate(-50%, -75%);
}
