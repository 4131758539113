.lease-form {
  position: relative;
  display: flex;
  height: 90%;
  width: 100%;
  padding: 20px 20px;
  font-family: "BPG Nino Medium", sans-serif;
}

.rent-apartment-container,
.rental-form-container,
.rental-parking-container {
  margin-left: 50px;
  width: 100%;
}

.rental-parking-container {
  width: 90%;
  margin-bottom: 50px;
  margin-left: 30px;
}

.rental-parking {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.rental-parking-row {
  display: flex;
  align-items: center;
  gap: 10px;
}

.rent-info-outter {
  width: 100%;
}
.rental-edit-icons {
  display: flex;
  align-items: center;
  gap: 10px;
  position: absolute;
  right: 80px;
  bottom: 80px;
}

.rent-apartment-container > label,
.rent-info-container > label,
.rental-form-container > label,
.rental-parking-container > label,
.rental-parking-title {
  font-weight: 900;
  font-size: 14px;
}

.rental-form {
  margin-top: 20px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
}

.rental-form-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
  margin-bottom: 15px;
}

.rental-form-row > div {
  flex-grow: 1;
}

.lease-form-right,
.lease-form-left {
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.rent-user {
  display: flex;
  gap: 10px;
  margin-top: 10px;
  font-size: 14px;
  border-bottom: 1px solid #828282;
  padding: 10px;
  margin-left: -20px;
}

.rent-users {
  margin-top: 40px;
}

.rental-info-item {
  position: relative;
  font-size: 13px;
  margin-bottom: 15px;
  color: black;
  padding: 10px 20px;
  padding-right: 0;
  padding-left: 5px;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--borderColor);
  word-spacing: 5px;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  gap: 20px;
  padding-right: 4px;
  border-radius: 3px;
}

.verification-text {
  width: 100%;
  text-align: right;
  color: var(--errorColor);
  font-size: 13px;
}

.verification-text:hover {
  text-decoration: underline;
}

.rent-apartment {
  padding: 10px;
  width: 100%;
  border-bottom: 1px solid #828282;
  font-size: 14px;
  margin-left: -20px;
}
