.country-dropdown {
    width: 100%;
    position: relative;
}

.country-input-container {
    position: relative;
}

.country-dropdown-arrow {
    position: absolute;
    width: 18px;
    height: 10px;
    right: 15px;
    top: 27px;
}

.dropdown-element-selected {
    background-color: var(--lightCyan);
}