.request-card {
    background: #FFFFFF;
    border: 0.5px solid #BDBDBD;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    position: relative;
    margin-bottom: 10px;
    padding: 10px;
    font-family: 'BPG Nino Medium', sans-serif;
    font-size: 15px;
    display: grid;
    grid-template-columns: 20% 20% 20% 20% 20%;
    cursor: pointer;
    font-size: 12px;
    min-height: 160px;
}

.request-cards-header {
    background: #FFFFFF;
    border: 0.5px solid #BDBDBD;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    position: relative;
    font-size: 13px;
    margin-bottom: 10px;
    padding: 10px;
    font-family: 'BPG Nino Medium', sans-serif;
    font-size: 15px;
    display: grid;
    grid-template-columns: 20% 20% 20% 20% 20%;
    cursor: pointer;
}

.request-list {
    height: 60vh;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}

.requst-list::-webkit-scrollbar {
    display: none;
}

.request-column {
    border-right: 1px solid black;
    padding: 5px 15px;
    position: relative;
}

.no-right-border {
    border-right: none;
}

.card-main-category, .card-sub-category {
    display: flex;
    align-items: center;
    margin-bottom: 2px;
    border-bottom: 1px solid black;
    padding: 5px 0;
    gap: 5px;
}

.card-sub-category {
    padding-left: 15px;
}

.card-main-category {
    padding-left: 3px;
}

.card-category-icon {
    width: 15px;
}

.executor {
    display: flex;
    flex-direction: column;
}

.executor-selector {
    padding-left: 15px;
    padding-right: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    cursor: pointer;
}

.executor-selector-arrow {
    width: 12px;
}

.assign-button {
    background-color: var(--errorColor);
    padding: 6px 6px;
    margin-top: 15px;
    border-radius: 6px;
    font-family: 'BPG Nino Mtavruli';
    color: white;
    align-self: flex-end;
    cursor: pointer;
}

.executor-drop {
    position: absolute;
    top: 30px;
    background-color: white;
    z-index: 100;
    font-size: 11px;
    box-shadow: 2px 2px 10px #828282;
    left: 5px;
    width: 100%;
}

.executor-form {
    display: flex;
    align-items: center;
    gap: 5px;
    border-top: 1px solid black;
    height: 30px;
    padding: 5px;

}

.executor-add-button {
    font-size: 9px;
    background-color: var(--errorColor);
    padding: 5px;
    border-radius: 3px;
    color: white;
    cursor: pointer;
}

.executor-input {
    border: none;
    outline: none;
    border-bottom: 1px solid #828282;
    padding: 6px;
    font-size: 10px;
    width: 100%;
}

.executor-input::placeholder {
    font-size: 9px;
}

.executor-input:focus {
    outline: none;
}

.executors-list {
    min-height: 30px;
    max-height: 90px;
    margin: 10px;
    overflow-y: auto;
}

.executors-list > p:hover {
    background-color: #ebebeb;
}
.executors-list > p {
    padding: 2px 4px;
    border-radius: 3px;
}

.button-disabled {
    opacity: 0.4;
}

.executor-name {
    position: relative;
    cursor: pointer;
    padding: 1px;

}

.executor-delete-icon {
    position: absolute;
    right: 2px;
    top: 2px;
    cursor: pointer;
}
.request-img {
    margin-top: 30px;
    width: 60px;
    height: 60px;
    object-fit: contain;
    cursor: pointer;
}

.request-img-big {
    width: 1000px;
    height: 600px;
    object-fit: contain;
    object-position: center;
}

.rate-stars {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-top: 50px;
}

.request-reaction-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
}

.request-approve-button, .request-reject-button {
    background-color: var(--errorColor);
    color: white;
    font-family: 'BPG Nino Mtavruli';
    width: 100px;
    height: 25px;
    border-radius: 5px;
    padding-top: 4px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.request-reject-button {
    background-color: #828282;
}

.sub-navigation {
    display: flex;
    margin-bottom: 10px;
    margin: 5px auto 20px auto;
}

.sub-navigation > div {
    width: 250px;
    font-family: 'BPG Nino Medium';
    height: 25px;
    border: 1px solid #828282;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    cursor: pointer;
    
}

.sub-navigation-left {
    border-radius: 5px 0 0 5px;
    opacity: 0.5;
}
.sub-navigation-right {
    border-radius: 0 5px 5px 0;
    opacity: 0.5;
}

.sub-navigation-selected {
    opacity: 1;
    border: 3px solid #828282;
}

.rejection-textarea {
    width: 500px;
}