.owners_card{
    background: #FFFFFF;
    border: 0.5px solid #BDBDBD;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    position: relative;
    margin-bottom: 10px;
    padding: 10px;
    font-family: 'BPG Nino Medium', sans-serif;
    font-size: 15px;
    display: grid;
    grid-template-columns: 46% 27% 27%;
    z-index: 100;
    cursor: pointer;
    /* align-items: center;
    justify-content: flex-start; */
}

.lease_card {
    background: #FFFFFF;
    border: 0.5px solid #BDBDBD;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    position: relative;
    margin-bottom: 10px;
    padding: 10px;
    font-family: 'BPG Nino Medium', sans-serif;
    font-size: 15px;
    display: grid;
    grid-template-columns: 50% 20% 15% 15%;
    cursor: pointer;
}

.visitor_card {
    background: #FFFFFF;
    border: 0.5px solid #BDBDBD;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    position: relative;
    margin-bottom: 10px;
    padding: 10px;
    font-family: 'BPG Nino Medium', sans-serif;
    font-size: 15px;
    display: grid;
    grid-template-columns: 50% 50%;
    cursor: pointer;
}

.table-rows{
    padding: 15px 15px 15px 15px;
    /* background-color: rgba(0, 0, 255, 0.594); */
    
}
.apartment-rows{
    border-left: 1px solid black;
    border-right: 1px solid black;
    margin-left: -0.5px;
    padding-left: 20px;
    padding-right: 20px;
}
.parking-rows{
    
    padding-left: 20px;
}

.lease-rows {
    margin: 16px 0 0 30px;
}

.parking-column{
    border-left: 1px solid black;
    margin-left: -0.5px;
}

.card-rows{
    border-right: 0.5px solid black;
    /* width: 50%; */
    padding-right: 20px;
    display: flex;
    flex-direction: column;
}

.card-rows > label {
    font-weight: 900;
    margin-left: 15px;
    margin-bottom: 15px;
    margin-top: 10px;
}

.owners-info{
    display: flex;
    align-items: center;
}
.owners-text{
    margin-left: 20px;
}
.lower-line{
    border-bottom: 1px solid black;
    /* padding-bottom: 0px !important; */
    /* background-color: rgba(255, 0, 0, 0.589); */
}
.upper-line{
    border-top: 1px solid black;
    /* padding-top: 0px !important; */
    /* background-color: rgba(0, 231, 0, 0.674); */
}
.phoneIcon{
    width: 12px;
    
}

.owners-header{
    background: #F0F0F7 !important;
    border: 0.5px solid #BDBDBD !important;
    box-shadow: none;
    font-weight: 700;
}
.header-cell{
    text-align: center;
}
/* Registration Modal box  */
.rectangle_box{
    background-color: #DAA161;
    font-family: 'BPG Nino Medium', sans-serif;
    font-size: 15px;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    position:absolute;
    left: 40px;
    min-width: 230px;
    padding: 20px 30px 10px 10px;
    /* justify-content: space-evenly; */
    box-sizing: border-box;
    z-index: 1000;
}
.application-date{
    margin-bottom: 5px;
}
.box-close{
    position: absolute;
    right: 5px;
    top: 5px;
}
.box-close img{
    justify-self: flex-end;
}
.box-off{
    display: none;
}
.box-on{
    display: flex;
}
/* ------------- Responsive part --------- */
