
.ratings-container{
    display: flex;
    justify-content: space-between;
    padding: 0px 30px 60px 30px;
    margin-top: -15px;
    width: 100%;
    height: auto;
    flex-flow: row wrap;
}
.ratings-container::after{
    content: '';
    width: 32%;
  }
@media screen and (max-width: 950px){
    .ratings-container{
        justify-content: center;
    }
    .ratings-container::after{
        content: '';
        width: 0;
      }
}