.tutorials-form{
    background-color: white;
    display: flex;
    width: 100%;
    /* height: 200px; */
    justify-content: space-between;
    padding: 18px;
    border: 0.5px solid #BDBDBD;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    box-sizing: border-box;
}
.tutorials-form *{
    height: 100%;
}
.tutorial-input {
    width: 44%;
    border: 2px solid #828282;
    border-radius: 7px;
    padding: 13px 10px 13px 20px;
    font-size: 14px;
    color:#828282;
    
}
.tutorial-input:focus{
    outline:none;
}
.tutorial-error{
    border: 2px solid red;
}
.tutorial-error:focus{
    border: 2px solid #828282;
}
.tutorials-form button{
    width: 100px;
    padding: 13px 16px 13px 16px;
    font-size: 16px;
    background-color: #DAA161;
    color:white;
    background: #DAA161;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 7px;
    text-align: center;
}
.save_button{
    background-color: #BDBDBD;
}

@media screen and (max-width: 1300px){
    .tutorials-form{
    }
    .tutorials-form input{
    margin-right: 20px;
    }
}
@media screen and (max-width: 800px){
    .tutorials-form{
        flex-direction: column;
    }
    .tutorial-input , .tutorials-form button{
      width: 100%;
      box-sizing: border-box;
    }
    .tutorial-input {
      margin-bottom: 20px;
    }
}