@import url('https://fonts.googleapis.com/css2?family=Antonio:wght@400;500&display=swap');

.login-inner {
    background: var(--backgroundLight);
    padding: 80px 0px;
    padding-bottom: 60px;
    text-align: center;
    font-size: 1.6rem;
    border-radius: 5px;
    margin: 20px;
    width: clamp(350px, 60vw, 900px);
}

.login-headers {
    color: var(--darkPrimary);
}

.login-headers > h1 {
    font-family: 'Antonio', sans-serif;
    font-weight: 500;
    font-size: 70px;
    margin: -25px 0 25px 0;
}

.login-headers > * {
    margin-bottom: 20px;
}

.login-form {
    font-size: 0.5em;
    position: relative;
}

.login-form > p {
    margin: 20px 0 10px 0;
    font-size: larger;
}

.form-input {
    padding: 15px 28px;
    border-radius: 5px;
    font-size: larger;
    font-family: 'BPG Nino Medium', sans-serif;
    border: 1px solid #E0E0E0;
    margin: 0px 0 10px 10px;
    width: 100%;
}

.input-container {
    margin-left: -20px;
    width: clamp(300px, 30vw, 100%);
    position: relative;
}

input.disabled, button.disabled {
    pointer-events: none;
    user-select: none;
}

.eye-icon {
    position: absolute;
    cursor: pointer;
    right: 5px;
    bottom: 25px;
}

.form-button {
    width: clamp(300px, 30vw, 100%);
    padding: 15px 0;
    color: white;
    border-radius: 3px;
    cursor: pointer;
    font-size: 1.2em;
    font-weight: 700;
    background-color: #333333;
}

.form-button:hover {
    background-color:#444444;
}

.error-border {
    border: 1px solid var(--errorColor);
    color: var(--errorColor);
}

.error-border::placeholder {
    color: var(--errorColor)
}

.copyright {
    padding: 20px 0;
    font-size: 16px;
    color: #bdbdbd;
}

.loading-button {
    background-color: #c0c0c0;
}

@keyframes popup {
    from {
        bottom: -100px;
    } to {
        bottom: -50px;
    }
}

.error-msg {
    position: absolute;
    background-color: var(--errorColor);
    font-family: 'BPG Nino Medium';
    font-size: larger;
    padding: 50px 60px;
    border-radius: 7px;
    bottom: -50px;
    animation: popup 0.1s ease-in-out;
}

.close-icon {
    position: absolute;
    cursor: pointer;
    right: 12px;
    top: 12px;
}

.loading-dots {
    font-size: 70px;
}

.untouchable {
    z-index: -1;
}