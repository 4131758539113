.textarea {
    display: flex;
    resize: none;
}

.text-area-container {
    width: 100%;
    position: relative;
}

.language-switcher {
    position: absolute;
    top: 20px;
    right: 10px;
    cursor: pointer;
    padding: 6px;
    text-align: center;
    border-radius: 3px;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.symbols-limit {
    position: absolute;
    bottom: 10px;
    right: 10px;
    font-size: 12px;
    color: rgb(111, 111, 111);
    user-select: none;
}

.red-outline {
    color: red;
}

.language-switcher:hover {
    background-color: rgb(241, 241, 241);
}