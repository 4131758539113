.input-field {
    width: 100%;
    position: relative;
    font-family: 'BPG Nino Medium';
}

.input-field input::placeholder {
    font-family: 'BPG Nino Medium';
    font-size: 11px;
}

.input-field label {
    position: absolute;
    font-size: 9px;
    top: 3px;
    left: 10px;
    padding: 2px;
    background-color: white;
}

.input-symbol-limit {
    position: absolute;
    bottom: 0;
    right: 10px;
    font-size: 11px;
    color: rgb(111, 111, 111);
}