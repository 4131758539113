.notifications-form {
  position: relative;
  display: flex;
  height: 90%;
  width: 100%;
  padding: 20px 20px;
  font-family: "BPG Nino Medium", sans-serif;
}

.notifications-form-right,
.notifications-form-left,
.lease-form-left,
.lease-form-right {
  width: 50%;
  border-right: 1px solid var(--borderColor);
  display: flex;
  justify-content: flex-start;
  padding: 0 100px;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  padding: 20px 0 0 0px;
  cursor: pointer;
}

.notifications-form-right,
.lease-form-right {
  border-right: none;
}
.notifications-form-right::-webkit-scrollbar-track {
  background: transparent;
  margin-top: 25px;
}

.notifications-form-right::-webkit-scrollbar {
  width: 5px;
}

.addresate-filter {
  padding: 0 50px;
  padding-bottom: 10px;
  width: 100%;
}

.addresate-filter > label,
.message-form > label,
.lease-form > label {
  font-family: "BPG Nino Medium", sans-serif;
  font-weight: 700;
  font-size: 14px;
}

.addresate-filter-inputs {
  margin-top: 30px;
}

.addresate-filter-row {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.addresate-filter-clear-button,
.addresate-put-button {
  width: 100%;
  display: inline;
  text-align: center;
  background-color: #bdbdbd;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 8px 0;
  box-shadow: 2px 5px 6px rgb(170, 170, 170);
  font-size: 12px;
  color: white;
}

.addresate-put-button {
  background-color: var(--errorColor);
}

.message-form {
  width: 100%;
  padding: 0 30px;
}

.message-form-inputs {
  margin-top: 20px;
}

.message-inputs-row {
  display: flex;
  justify-content: space-between;
  padding-bottom: 50px;
}

.message-date-input {
  width: 250px;
  color: #828282;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 45px;
}

.message-file-input {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
}

.message-file-input > img {
  cursor: pointer;
}

.file-input-warnings {
  font-size: 8px;
  color: #828282;
}

.calendar-inner {
  display: flex;
}

.datetime-input-modal {
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
}

.calendar-title {
  font-family: "BPG Nino Medium", sans-serif;
  font-size: 17px;
  text-align: center;
  width: 100%;
  display: inline-block;
}

.calendar-left {
  border-right: 1px solid #828282;
  padding-right: 50px;
}

.calendar-right {
  width: 300px;
  padding-left: 50px;
}

.calendar-put {
  font-size: 13px;
  text-align: center;
  background-color: var(--errorColor);
  width: 200px;
  color: white;
  padding: 8px 0;
  border-radius: 5px;
  font-family: "BPG Nino Medium", sans-serif;
  cursor: pointer;
}

/* .addresate-filter-row > .filter-input-container {
  width: 25%;
  margin-right: 20px;
} */

.notifications-form-left {
  flex-direction: column;
  gap: 30px;
}

.addresate-list,
.apartment-list {
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 50px;
  padding-right: 20px;
}

.addresate,
.check-every-addresate {
  border-bottom: 1px solid var(--borderColor);
  padding: 2px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 6px;
}

.addresates-loader {
  margin: auto;
  margin-top: 50px;
  width: 50px;
  height: 50px;
}

.message-form-files {
  font-size: 16px;
  color: #061095;
  margin-top: 20px;
  flex-direction: column;
  gap: 5px;
  min-width: 50%;
  margin-right: 0;
}

.message-form-file {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 15px;
  gap: 10px;
}

.message-form-file:hover {
  text-decoration: underline;
}

.message-files {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.notifications-cards-header,
.notifications-cards-header-recieved {
  background: #ffffff;
  border: 0.5px solid #bdbdbd;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: relative;
  font-size: 13px;
  margin-bottom: 10px;
  padding: 10px;
  font-family: "BPG Nino Medium", sans-serif;
  font-size: 15px;
  display: grid;
  grid-template-columns: 25% 40% 20% 15%;
  cursor: pointer;
}

.notifications-cards-header-recieved {
  grid-template-columns: 20% 50% 30%;
}

.notifications-list {
  height: 60vh;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.controlbar-notification-pointer {
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 100;
  background-color: #eb5757;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 3px;
  font-size: 11px;
  font-weight: 100;
}
