.form-table {
  position: relative;
  max-height: 600px;
  overflow: auto;
}
.owner-form {
  position: relative;
  display: flex;
  height: 90%;
  width: 100%;
  padding: 20px 20px;
  font-family: "BPG Nino Medium", sans-serif;
}

.owner-form label {
  font-family: "BPG Nino Medium", sans-serif;
  font-weight: 700;
  font-size: 14px;
}

.form-item > label {
  margin-bottom: 20px;
}

.owner-form-left,
.owner-form-right {
  width: 50%;
  border-right: 1px solid var(--borderColor);
  display: flex;
  justify-content: flex-start;
  padding: 0 100px;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
}

.space-between {
  justify-content: space-between;
}

.owner-form-right::-webkit-scrollbar {
  display: block;
  width: 5px;
  height: 300px;
}
.owner-form-right::-webkit-scrollbar-track {
  background: transparent;
  margin-top: 25px;
}

.owner-form-right::-webkit-scrollbar-thumb {
  background-color: #bdbdbd;
  border-right: none;
  border-left: none;
}

.owner-form-right::-webkit-scrollbar-track-piece:end {
  background: transparent;
  margin-bottom: 10px;
}

.owner-form-right {
  border-right: none;
}

.ownership-title-component {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 15px;
  margin-top: 15px;
}

.form-actions {
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid var(--borderColor);
  gap: 30px;
  width: 100%;
  height: 10%;
  padding-top: 20px;
  margin-top: -20px;
}

.form-item {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  margin-left: -40px;
  margin-bottom: 20px;
}

.lease-item {
  margin-top: 50px;
}

.lease-item > label {
  margin-left: 30px;
  margin-bottom: 30px;
}

.status-dropdown {
  width: 400px;
}

.country-index {
  grid-area: ind;
}
.tel {
  grid-area: tel;
}
.name {
  grid-area: name;
}
.lastname {
  grid-area: last;
}
.id-number {
  grid-area: id;
}

.person-form {
  display: grid;
  grid-template-areas:
    "ind ind ind ind tel tel tel tel tel tel tel tel"
    "name name name name last last last last id id id id";
  column-gap: 10px;
}

.input {
  border: 1px solid var(--borderColor);
  width: 100%;
  padding: 15px;
  margin-top: 10px;
  border-radius: 5px;
  font-size: 11px;
  cursor: pointer;
}

.organization-form {
  display: flex;
  gap: 20px;
  margin-bottom: 30px;
}

.actual-person {
  margin-top: 20px;
  position: relative;
}

.put-button {
  background-color: var(--errorColor);
  display: inline-block;
  align-self: flex-end;
  font-size: 14px;
  margin-top: 10px;
  padding: 10px 40px;
  border-radius: 5px;
  color: white;
  box-shadow: 2px 5px 6px rgb(170, 170, 170);
  cursor: pointer;
}

.apartment-data-form {
  display: flex;
  align-items: center;
  gap: 15px;
}

.parking-data-form,
.parking-data-dates {
  display: flex;
  align-items: center;
  gap: 15px;
}

.parking-data-dates {
  justify-content: space-between;
}

.parking-data-dates input {
  width: 30%;
}

.input-date {
  position: relative;
  width: clamp(80px, 10vw, 210px);
}

.date-inputs {
  display: flex;
  align-items: center;
  gap: 15px;
}

.input-date > input {
  width: 100%;
  padding: 10px;
}

.input-date > p {
  position: absolute;
  font-size: 10px;
  color: var(--borderColor);
  background-color: white;
  padding: 5px;
  top: 0px;
  left: 5px;
  text-align: center;
  color: black;
}

.parking-dropdown {
  width: 150%;
}

.form-btn-primary,
.form-btn-secondary {
  color: white;
  padding: 12px 0px;
  text-align: center;
  width: 200px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
}

.form-btn-primary {
  background-color: #10202b;
}

.form-btn-secondary {
  background-color: #10202b80;
}

.form-close {
  position: absolute;
  cursor: pointer;
  top: 15px;
  right: 15px;
  width: 20px;
}

.reverse {
  display: flex;

  flex-direction: row-reverse;
}

.input-error {
  border: 1px solid var(--errorColor);
}

.input-error::placeholder {
  color: var(--errorColor);
}

.card-item-list {
  margin: 0px 0;
}

.card-item {
  position: relative;
  font-size: 13px;
  margin-bottom: 15px;
  color: black;
  padding: 10px 20px;
  padding-right: 0;
  padding-left: 5px;
  border-bottom: 1px solid var(--borderColor);
  word-spacing: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  gap: 10px;
  padding-right: 4px;
  border-radius: 3px;
}

.bin {
  width: 15px;
  cursor: pointer;
}

.owners-card-list {
  width: 110%;
}

.edit-add-icon {
  width: 25px;
  align-self: flex-end;
  cursor: pointer;
}

.edit-icon {
  width: 20px;
  cursor: pointer;
}

.edit-icons {
  display: flex;
  gap: 10px;
  align-items: center;
  right: 5px;
  top: 5px;
}

.add-button-disabled {
  opacity: 0.4;
}

.editing {
  color: var(--errorColor);
}

.responsible-person-checkbox {
  margin-bottom: 10px;
}

.input-outer {
  position: relative;
  width: 100%;
}

.input-title {
  position: absolute;
  font-size: 9px;
  top: 2px;
  left: 8px;
  background-color: white;
  padding: 3px;
}

@keyframes popup {
  from {
    bottom: -100px;
  }
  to {
    bottom: -50px;
  }
}

.form-error-msg {
  top: 50%;
  transform: translateY(-50%);
  height: fit-content;
  width: 300px;
  text-align: center;
  z-index: 2;
  animation: popup 0.1s ease-in-out;
}

@media only screen and (max-width: 1300px) {
  .apartment-data-form,
  .parking-data-form,
  .parking-data-dates {
    display: flex;
    flex-direction: column;
    gap: 0px;
  }

  .date-inputs {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .input-date {
    width: 100%;
  }

  .owner-form-right,
  .owner-form-left {
    padding-left: 60px;
    padding-right: 60px;
  }

  .parking-dropdown {
    width: 100%;
  }

  .person-form {
    display: flex;
    flex-direction: column;
  }

  .organization-form {
    flex-direction: column;
    gap: 0;
  }
}
