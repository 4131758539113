.module_card {
  background-image: linear-gradient(white 50%, #061095b2 50%);
  width: 360px;
  height: 220px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  cursor: pointer;
  color: white;
  position: relative;
}

.module_card:hover {
  transform: scale(1.05, 1.05);
  transition: 0.1s ease-in;
}

.module_icon {
  background-color: #061095;
  width: calc(100% * 0.375);
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  border: 2px solid white;
}
.module_name {
  margin-top: 20px;
  text-align: center;
}

.module_nameEn {
  margin-top: 10px;
  font-size: 14px;
  text-align: center;
}
