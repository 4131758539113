.constructor-table {
  display: flex;
  align-items: center;
  padding: 50px 0;
  font-family: "BPG Nino Medium";
}

.constructor-col-title {
  font-size: 14px;
  font-family: "BPG Nino Medium";
  font-weight: 700;
  margin-bottom: 30px;
}

.constructor-col {
  border-right: 1px solid #828282;
  width: 33.5%;
  height: 100%;
  overflow-y: auto;
  padding: 0 40px;
}

.no-right-border {
  border-right: none;
}

.categories-list {
  margin-top: 35px;
  font-size: 14px;
  font-family: "BPG Nino Medium";
}

.category-list-item {
  margin-bottom: 20px;
  padding: 15px 0;
  border-bottom: 1px solid #828282;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 25px;
}

.category-list-item-left,
.category-list-item-right {
  display: flex;
  align-items: center;
  gap: 5px;
}

.category-list-item-left {
  gap: 10px;
}

.checkbox {
  width: 18px;
  height: 18px;
  border: 1px solid black;
  border-radius: 3px;
  cursor: pointer;
  font-size: 15px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.constructor-list-icon {
  width: 20px;
}

.check-selected {
  border-color: var(--errorColor);
  color: var(--errorColor);
}

.category-skeleton {
  margin-bottom: 15px;
}

.service-visibility-icon {
  cursor: pointer;
}
.service-edit-icon {
  cursor: pointer;
}

.category-input {
  margin-left: -10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.category-input-field {
  padding-right: 40px;
}

.category-input-fields {
  display: flex;
  align-items: center;
  gap: 5px;
}

.category-input-fields > input {
  margin-bottom: 10px;
}

.icon-upload {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1px;
  width: 60px;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 1px solid var(--borderColor);
  cursor: pointer;
  padding-top: 5px;
  margin-top: 10px;
}

.icon-upload > p {
  font-size: 12px;
}

.category-put-button {
  background-color: var(--errorColor);
  display: inline-block;
  align-self: flex-end;
  font-size: 12px;
  padding: 8px 0;
  width: 70px;
  text-align: center;
  border-radius: 5px;
  color: white;
  box-shadow: 2px 5px 6px rgb(170, 170, 170);
  cursor: pointer;
}

.services-inputs {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 40px;
}

.services-inputs-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.services-inputs-col-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.services-inputs-col {
  flex: 1;
  height: 110px;
}

.services-input-checkbox {
  display: flex;
  align-items: center;
  margin-top: 10px;
  gap: 10px;
}

.services-input-checkbox > label {
  font-size: 11px;
  color: #828282;
  margin-top: 2px;
}

.loader {
  width: 8px;
  height: 8px;
  border: 1px solid #fff;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.fees {
  border-bottom: 1px solid black;
}

.fees-container {
  display: flex;
  flex-direction: column;
}

.fees-edit-icon {
  margin-top: 20px;
  align-self: flex-start;
  margin-left: 20px;
  cursor: pointer;
}

.fee-save-button {
  align-self: center;
  margin-left: 20px;
}

.fee {
  font-size: 14px;
  font-family: "BPG Nino Medium";
  margin-bottom: 20px;
  display: flex;
  gap: 30px;
  justify-content: space-between;
  flex-wrap: wrap;
}

.fee-tariff {
  align-self: flex-end;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}

.fee-currency {
  font-size: 25px;
  font-weight: 700;
  margin-top: 10px;
}

.fee-currency-arrow {
  width: 10px;
  margin-left: 2px;
}

.fee-price {
  font-size: 50px;
  width: 130px;
  border: none;
}

.fee-data > p {
  margin-bottom: 5px;
}

.fee-currency-selector {
  cursor: pointer;
  position: relative;
  font-size: 15px;
}

.service-textarea {
  padding-right: 40px;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.icon-drop {
  position: absolute;
  background-color: white;
  border: 1px solid #828282;
  border-top: none;
  display: grid;
  grid-template-columns: 50px 50px 50px 50px;
  padding: 20px;
  row-gap: 20px;
  column-gap: 10px;
  top: 55px;
  left: 0;
  z-index: 1;
  height: 400px;
  overflow-y: auto;
}

.fee-currencies-drop {
  border: 1px solid black;
  border-top: none;
  position: absolute;
  background-color: white;
  padding: 0;
  right: -8px;
  margin-top: 5px;
  z-index: 1;
}

.fee-currencies-drop > p {
  margin-bottom: 5px;
  padding: 3px 15px;
  width: 100%;
}

.fee-currencies-drop > p:hover {
  background-color: #d1d1d1;
}

.working_hour_row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  margin-bottom: 10px;
}

.working_hours_container {
  margin-top: 40px;
}

.working_hour_inputs {
  display: flex;
  gap: 15px;
}

.working_hours_container > label {
  font-size: 11px;
  font-weight: 700;
  color: #444444;
  margin-top: 2px;
}

.working_hour_row > label {
  font-size: 11px;
  color: #828282;
  margin-top: 10px;
}

.service-update-icon {
  align-self: flex-end;
  cursor: pointer;
}

@media only screen and (max-width: 1200px) {
  .category-list-item {
    gap: 10px;
    font-size: 12px;
  }

  .service-edit-icon,
  .service-visibility-icon {
    width: 15px;
  }
}

@media only screen and (max-width: 1000px) {
  .services-inputs-row {
    flex-wrap: wrap;
  }
  .category-input-fields {
    flex-wrap: wrap;
  }
  .icon-upload {
    width: 60px;
  }
}

@media only screen and (max-width: 1200px) {
  .constructor-col-title {
    margin-top: 0;
  }
  .constructor-col {
    overflow-y: auto;
  }
}

@media only screen and (max-height: 900px) {
  .constructor-col-title {
    margin-top: 0;
  }
  .constructor-col {
    overflow-y: auto;
    width: 100%;
    height: 400px;
    padding-top: 30px;
  }
}
