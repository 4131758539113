.modules {
  width: 100%;
  display: flex;
  text-transform: uppercase;
  justify-content: center;
  align-items: center;
  margin-top: 0px;
  margin-left: -50px;
  gap: 50px 0px;
  flex-wrap: wrap;
  flex-direction: column;
}

.module-container {
  width: 100%;
  flex-grow: 1;
}

.modules-row {
  display: flex;
  align-items: center;
  gap: 30px;
  width: 70%;
}

.notifications-module-card {
  position: relative;
}

.notification-pointer {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  text-align: center;
  right: 12px;
  top: 12px;
  background-color: #eb5757;
  color: white;
  font-size: 12px;
  padding-top: 2px;
}

@media screen and (max-width: 1300px) {
  .modules {
    margin-left: -120px;
  }
}

@media screen and (max-width: 1100px) {
  .modules {
    margin-left: 0;
  }
  .modules-row {
    flex-direction: column;
    gap: 50px;
  }
}
