.dropdown-container {
    position: relative;
    width: 100%;
}

.dropdown-selector {
    position: relative;
    border: 1px solid var(--borderColor);
    width: 100%;
    padding: 15px;
    margin-top: 10px;
    border-radius: 5px;
    font-size: 11px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
}

.arrow {
    width: 18px;
    height: 10px;
}

.dropdown-notselected {
    color: var(--borderColor);
}

.dropdown-list {
    font-size: 11px;
    position: absolute;
    background-color: white;
    width: 100%;
    max-height: 220px;
    overflow: auto;
    z-index: 1;
    border: 1px solid var(--borderColor);
    border-top: none;
}


.dropdown-element {
    padding: 15px;
    width: 100%;
    cursor: pointer;
    margin-top: 2px;
}

.dropdown-element:hover {
    background-color: var(--lightCyan);
}

.dropdown-title {
    position: absolute;
    top: -10px;
    font-size: 9px;
    text-align: center;
    left: 9px;
    padding: 3px;
    background-color: white;
}

.dropdown-selector > p {
    font-size: 9px;
}


@media only screen and (max-width: 1200px) {
    .dropdown-selector {
        font-size: 12px;
    }
}