.controlbar-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--lightCyan);
  margin-bottom: 15px;
  padding-left: 30px;
  box-shadow: 0px 2px 10px rgb(170, 170, 170);
  border-radius: 3px;
  font-family: "BPG Nino Medium", sans-serif;
  font-weight: 700;
}

.controlbar-right,
.controlbar-left {
  display: flex;
  align-items: center;
  height: 100%;
  overflow: auto;
}

.controlbar-left {
  gap: 25px;
  position: relative;
  flex: 1;
  overflow: visible;
}
.controlbar-category-filter {
  position: absolute;
  left: 50px;
}

.controlbar-left > * {
  cursor: pointer;
}

.selector {
  padding: 20px;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;
}

.selector:hover {
  color: var(--errorColor);
  background-color: white;
}

.table {
  background-color: white;
  width: 100%;
  height: 65vh;
  box-shadow: 0px 2px 10px rgb(170, 170, 170);
  border-radius: 5;
}

.selector-selected {
  color: var(--errorColor);
  background-color: white;
}

.card-skeleton {
  margin-bottom: 30px;
}

.owners-list {
  height: 60vh;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.owners-list-table {
  display: flex;
  flex-direction: column;
}

.test-scroll {
  background-color: red;
  height: 40px;
  overflow-y: auto;
}

@media only screen and (max-width: 1250px) {
  .selector {
    padding: 20px 10px;
    font-size: 13px;
  }

  .total_cost_text {
    font-size: 14px;
    margin-left: -10px;
    margin-top: 5px;
  }
}

@media only screen and (max-width: 860px) {
  .controlbar-right {
    display: none;
  }

  .consturctor-col {
    width: 100%;
  }

  .table {
    flex-direction: column;
    width: 100%;
    height: auto;
  }

  .controlbar-container {
    height: 50px;
  }
}
