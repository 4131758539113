.footer_component{
    width: 100%;
    padding: 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    color: #333333;
    font-size: 15px;
    
}

.footer_component a{
    text-decoration: none;
    color: #333333;
}