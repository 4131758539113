.tutorials-card{
 display: flex;
 flex-direction: column;
 background-color: white;
 width: 32%;
 height: auto;
 margin-top: 40px;
 padding: 32px;
}
.card-head{
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    font-size: 18px;
    align-items: center;
}
.icons{
    display: flex;
    
}
.title{
    width: 90%;
    overflow-wrap: break-word;
}
.icons img{
    width:  22px;
    height: 22px;
}
.video{
    
}
.bin-icon{
    margin-left: 5px;
    cursor: pointer;
}
/* -----------------Responsive part ------------- */
@media screen and (max-width: 1200px){

    .tutorials-card{
        width: 47%;
    }
}

@media screen and (max-width: 900px){

    .tutorials-card{
        width: 80%;
    }
}