.modal-container{
width: 100%;
height: 100vh;
position:fixed;
left: 0;
top: 0;
/* background-color:  rgba(16, 32, 43, 0.5); */
display: flex;
align-items: center;
justify-content: center;
z-index: 2;
}
.dark-background{
left: 0;
top: 0;
width: 100%;
height: 100vh;
z-index: -1;
background-color:  rgba(16, 32, 43, 0.5);
position: absolute;
}
.modal-message-container{
width:  30%;
height: 30%;
background-color: white;
border: 1px solid #10202B;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
border-radius: 10px;
display: flex;
flex-direction: column;
justify-content: space-between;
align-items: center;
padding: 20px;
}
.modal-message{

}
.agree-button{
    background: #10202B;
    border-radius: 5px;
    color:white;
    font-size: 18px;
    padding: 10px 40px 10px 40px;
}
.closeButton{
justify-self: flex-end;
align-self:flex-end;
}