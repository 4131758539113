.invoice-data-form-inputs-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
}

.invoice-form-cost {
  width: 100%;
  position: relative;
  padding: 5px;
  padding-top: 0px;
}

.currency-toggler {
  position: absolute;
  right: 15px;
  top: 22px;
  z-index: 1;
  background-color: white;
}

.currency-selector {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #828282;
  width: 50px;
  border-radius: 3px;
  padding-top: 2px;
}

.currency {
  text-align: center;
  padding: 3px 0;
}

.currency:hover {
  background-color: #b3b3b3;
}
